._TUXModal-wrapper {
  box-sizing: border-box;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: auto;

  --tux-modalTransitionDuration: 0.3s;
}

._TUXModal-verticalPositionWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  max-height: 100vh;
  overflow: visible;
}

._TUXModal-verticalPaddingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 32px 16px;
  max-width: 100vw;
}

._TUXModal-backdrop {
  box-sizing: border-box;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: var(--tux-colorSDSecondary);
  opacity: 0;

  transition-property: opacity;
  transition-duration: var(--tux-modalTransitionDuration);
  transition-timing-function: linear;
}

._TUXModal-backdrop--entering {
  opacity: 1;
}

._TUXModal-backdrop--entered {
  opacity: 1;
}

._TUXModal-backdrop--exiting {
  opacity: 0;
}

._TUXModal-backdrop--exited {
  opacity: 0;
}

._TUXModal {
  box-sizing: border-box;

  background-color: var(--tux-colorBGPrimary);
  box-shadow: var(--tux-boxShadowNotice);

  max-width: calc(100vw - 32px);
  overflow: hidden;

  border-radius: 8px;
  outline: 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  transition-property: transform, opacity;
  transition-duration: var(--tux-modalTransitionDuration);
  transition-timing-function: linear;
}

._TUXModal--width-Small {
  width: 400px;
}

._TUXModal--width-Medium {
  width: 764px;
}

._TUXModal--width-Large {
  width: 1000px;
}

._TUXModal--width-Compact {
  width: auto;
}

._TUXModal--width-Full {
  width: calc(100vw - 32px);
}

._TUXModal--height-Compact {
  height: auto;
}

._TUXModal--height-Full {
  height: calc(100vh - 64px);
  min-height: 178px;
}

._TUXModal--transparent {
  background-color: none;
}

._TUXModal--entering {
  transform: scale(1);
  opacity: 1;
}

._TUXModal--entered {
  transform: scale(1);
  opacity: 1;
}

._TUXModal--exiting {
  transform: scale(1);
  opacity: 0;
}

._TUXModal--exited {
  transform: scale(0.8);
  opacity: 0;
}

._TUXModal-closeButtonWrapper {
  position: absolute;
  top: 26px;
  right: 24px;
}

body[dir='rtl'] ._TUXModal-closeButtonWrapper {
  left: 24px;
  right: auto;
}
