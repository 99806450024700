._TUXInputLabel {
  display: flex;
  box-sizing: border-box;
  align-items: center;
}

._TUXInputLabel--hidden {
  display: none;
}

._TUXInputLabel-tooltipIcon {
  box-sizing: border-box;
  margin-inline-start: 6px;
  height: 16px;
  width: 16px;
}
