._TUXRadioStandalone-container {
  box-sizing: border-box;
  display: inline-flex;
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 999px;
}

._TUXRadioStandalone-container--disabled {
  opacity: 0.34;
  background-color: var(--tux-colorBGInput);
}

._TUXRadioStandalone-input {
  box-sizing: border-box;
  appearance: none;
  height: 100%;
  width: 100%;
  margin: 0;
  cursor: pointer;
}

._TUXRadioStandalone-input:disabled {
  cursor: not-allowed;
}

._TUXRadioStandalone-circleOutside {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  color: var(--tux-colorLineSecondary);
}

/* Light up the outer circle when the input is checked */
[data-tux-radio-input]:checked ~ ._TUXRadioStandalone-circleOutside {
  color: var(--tux-colorPrimary);
}

/* Light up the outer circle when the input is hovered or focused */
[data-tux-radio-input]:not(:disabled):hover ~ ._TUXRadioStandalone-circleOutside,
[data-tux-radio-input]:focus-visible ~ ._TUXRadioStandalone-circleOutside {
  color: rgba(234, 40, 78, 1);
}

/* Light up the outer circle when the input is active */
[data-tux-radio-input]:not(:disabled):active ~ ._TUXRadioStandalone-circleOutside {
  color: rgba(224, 39, 75, 1);
}

._TUXRadioStandalone-circleInside {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  color: var(--tux-colorPrimary);
  pointer-events: none;
  visibility: hidden;
}

/* Show up the inner circle when the input is checked */
[data-tux-radio-input]:checked ~ ._TUXRadioStandalone-circleInside {
  visibility: visible;
}

/* Light up the inner circle when the input is hovered or focused */
[data-tux-radio-input]:not(:disabled):hover ~ ._TUXRadioStandalone-circleInside,
[data-tux-radio-input]:focus-visible ~ ._TUXRadioStandalone-circleInside {
  color: rgba(234, 40, 78, 1);
}

/* Light up the inner circle when the input is active */
[data-tux-radio-input]:not(:disabled):active ~ ._TUXRadioStandalone-circleInside {
  color: rgba(224, 39, 75, 1);
}
