:root,
[data-theme='light'],
[data-tux-theme='light'] {
  --tux-colorPrimary: rgba(254, 44, 85, 1);
  --tux-colorPrimary-tint-4: rgba(244, 42, 82, 1);
  --tux-colorPrimary-tint-8: rgba(234, 40, 78, 1);
  --tux-colorPrimary-tint-12: rgba(224, 39, 75, 1);
  --tux-colorPrimary-opacity-0: rgba(254, 44, 85, 0);
  --tux-colorPrimary-opacity-4: rgba(254, 44, 85, 0.04);
  --tux-colorPrimary-opacity-8: rgba(254, 44, 85, 0.08);
  --tux-colorPrimary-opacity-12: rgba(254, 44, 85, 0.12);

  --tux-colorLink: rgba(254, 44, 85, 1);
  --tux-colorPrimary2: rgba(22, 24, 35, 1);

  --tux-colorTextPrimary: rgba(22, 24, 35, 1);
  --tux-colorTextSecondary: rgba(22, 24, 35, 0.75);
  --tux-colorTextTertiary: rgba(22, 24, 35, 0.5);
  --tux-colorTextQuaternary: rgba(22, 24, 35, 0.34);

  --tux-colorBGPrimary: rgba(255, 255, 255, 1);

  --tux-colorBGSecondary: rgba(255, 255, 255, 1);
  --tux-colorBGSecondary-tint-4: rgba(245, 245, 245, 1);
  --tux-colorBGSecondary-tint-8: rgba(235, 235, 235, 1);
  --tux-colorBGSecondary-tint-12: rgba(224, 224, 224, 1);
  --tux-colorBGSecondary-opacity-0: rgba(255, 255, 255, 0);
  --tux-colorBGSecondary-opacity-4: rgba(255, 255, 255, 0.04);
  --tux-colorBGSecondary-opacity-8: rgba(255, 255, 255, 0.08);
  --tux-colorBGSecondary-opacity-12: rgba(255, 255, 255, 0.12);

  --tux-colorBGTertiary: rgba(255, 255, 255, 1);
  --tux-colorBGQuaternary: rgba(255, 255, 255, 1);
  --tux-colorBGQuinary: rgba(255, 255, 255, 1);
  --tux-colorBGSecondary2: rgba(255, 255, 255, 1);
  --tux-colorBGTertiary2: rgba(255, 255, 255, 1);
  --tux-colorBGPlaceholderDefault: rgba(22, 24, 35, 0.06);
  --tux-colorBGPlaceholderOpaque: rgba(241, 241, 241, 1);
  --tux-colorBGInput: rgba(22, 24, 35, 0.06);
  --tux-colorBGInput2: rgba(22, 24, 35, 0.06);
  --tux-colorBGView: rgba(22, 24, 35, 0.03);
  --tux-colorBGCreation3: rgba(248, 248, 248, 1);
  --tux-colorBGCreation4: rgba(255, 255, 255, 1);
  --tux-colorBGHover: rgba(22, 24, 35, 0.06);
  --tux-colorBGBrand: rgba(254, 44, 85, 0.06);

  --tux-colorLinePrimary: rgba(22, 24, 35, 0.2);
  --tux-colorLineSecondary: rgba(22, 24, 35, 0.12);
  --tux-colorLineInput: rgba(22, 24, 35, 0.5);
  --tux-colorLinePrimary2: rgba(22, 24, 35, 0.2);
  --tux-colorLineSecondary2: rgba(22, 24, 35, 0.12);
  --tux-colorLineSecondary3: rgba(22, 24, 35, 0.12);
  --tux-colorLineDarker: rgba(22, 24, 35, 0.12);

  --tux-colorSDSecondary: rgba(0, 0, 0, 0.5);
  --tux-colorSDTertiary: rgba(0, 0, 0, 0.2);
}
