:root {
  --tux-colorPositive: rgba(11, 224, 155, 1);
  --tux-colorNegative: rgba(255, 76, 58, 1);
  --tux-colorToastDefault: rgba(84, 84, 84, 0.92);
  --tux-colorToastNegative: rgba(255, 76, 58, 0.92);
  --tux-colorSecondary: rgba(32, 213, 236, 1);
  --tux-colorSecondary2: rgba(16, 162, 197, 1);
  --tux-colorPositive2: rgba(79, 171, 126, 1);

  --tux-colorConstTextInverse: rgba(255, 255, 255, 1);
  --tux-colorConstTextInverse2: rgba(255, 255, 255, 0.9);
  --tux-colorConstTextInverse3: rgba(255, 255, 255, 0.75);
  --tux-colorConstTextInverse4: rgba(255, 255, 255, 0.5);
  --tux-colorConstTextInverse5: rgba(255, 255, 255, 0.34);
  --tux-colorConstTextPrimary: rgba(22, 24, 35, 1);

  --tux-colorConstBGInverse: rgba(0, 0, 0, 1);
  --tux-colorConstBGInverse2: rgba(0, 0, 0, 0.6);
  --tux-colorConstBGInverse3: rgba(37, 37, 37, 0.6);
  --tux-colorConstBGInverse4: rgba(84, 84, 84, 0.5);
  --tux-colorBGCreation: rgba(0, 0, 0, 1);
  --tux-colorBGCreation2: rgba(27, 27, 27, 1);
  --tux-colorBGCreation5: rgba(0, 0, 0, 0.15);
  --tux-colorConstBGMat: rgba(255, 255, 255, 0.2);
  --tux-colorConstBGContainer: rgba(255, 255, 255, 0.12);
  --tux-colorConstBGContainer2: rgba(255, 255, 255, 0.08);
  --tux-colorConstBGContainer3: rgba(255, 255, 255, 0.04);
  --tux-colorConstBGPlaceholderOpaque: rgba(241, 241, 241, 1);
  --tux-colorConstBGInverseOpaque: rgba(37, 37, 37, 1);
  --tux-colorConstBGInverse1: rgba(56, 56, 56, 0.95);

  --tux-colorConstLineInverse: rgba(255, 255, 255, 0.2);
  --tux-colorConstLineInverse2: rgba(255, 255, 255, 0.12);

  --tux-colorSDPrimary: rgba(0, 0, 0, 0.8);

  --tux-gradientLive: linear-gradient(131.17deg, #ff1764 0%, #ed3495 94.15%);
  --tux-gradientVideoCoverOverlay: linear-gradient(
    180deg,
    rgba(22, 24, 35, 0.0001) 2.92%,
    rgba(22, 24, 35, 0.5) 98.99%
  );

  --tux-colorAssistColorYellow: rgba(250, 206, 21, 1);
}
