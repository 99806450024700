[data-theme='dark'],
[data-tux-theme='dark'] {
  --tux-colorPrimary: rgba(255, 59, 92, 1);
  --tux-colorPrimary-tint-4: rgba(255, 67, 99, 1);
  --tux-colorPrimary-tint-8: rgba(255, 75, 105, 1);
  --tux-colorPrimary-tint-12: rgba(255, 83, 112, 1);
  --tux-colorPrimary-opacity-0: rgba(255, 59, 92, 0);
  --tux-colorPrimary-opacity-4: rgba(255, 59, 92, 0.04);
  --tux-colorPrimary-opacity-8: rgba(255, 59, 92, 0.08);
  --tux-colorPrimary-opacity-12: rgba(255, 59, 92, 0.12);

  --tux-colorLink: rgba(255, 59, 92, 1);
  --tux-colorPrimary2: rgba(255, 255, 255, 0.9);

  --tux-colorTextPrimary: rgba(255, 255, 255, 0.9);
  --tux-colorTextSecondary: rgba(255, 255, 255, 0.75);
  --tux-colorTextTertiary: rgba(255, 255, 255, 0.5);
  --tux-colorTextQuaternary: rgba(255, 255, 255, 0.34);

  --tux-colorBGPrimary: rgba(18, 18, 18, 1);

  --tux-colorBGSecondary: rgba(37, 37, 37, 1);
  --tux-colorBGSecondary-tint-4: rgba(46, 46, 46, 1);
  --tux-colorBGSecondary-tint-8: rgba(54, 54, 54, 1);
  --tux-colorBGSecondary-tint-12: rgba(63, 63, 63, 1);
  --tux-colorBGSecondary-opacity-0: rgba(37, 37, 37, 0);
  --tux-colorBGSecondary-opacity-4: rgba(37, 37, 37, 0.04);
  --tux-colorBGSecondary-opacity-8: rgba(37, 37, 37, 0.08);
  --tux-colorBGSecondary-opacity-12: rgba(37, 37, 37, 0.12);

  --tux-colorBGTertiary: rgba(46, 46, 46, 1);
  --tux-colorBGQuaternary: rgba(56, 56, 56, 1);
  --tux-colorBGQuinary: rgba(75, 75, 75, 1);
  --tux-colorBGSecondary2: rgba(255, 255, 255, 0.08);
  --tux-colorBGTertiary2: rgba(255, 255, 255, 0.12);
  --tux-colorBGPlaceholderDefault: rgba(255, 255, 255, 0.08);
  --tux-colorBGPlaceholderOpaque: rgba(37, 37, 37, 1);
  --tux-colorBGInput: rgba(255, 255, 255, 0.12);
  --tux-colorBGInput2: rgba(255, 255, 255, 0.08);
  --tux-colorBGView: rgba(255, 255, 255, 0.04);
  --tux-colorBGCreation3: rgba(18, 18, 18, 1);
  --tux-colorBGCreation4: rgba(27, 27, 27, 1);
  --tux-colorBGBrand: rgba(254, 44, 85, 0.34);

  --tux-colorLinePrimary: rgba(255, 255, 255, 0);
  --tux-colorLineSecondary: rgba(255, 255, 255, 0.12);
  --tux-colorLineInput: rgba(255, 255, 255, 0.5);
  --tux-colorLinePrimary2: rgba(255, 255, 255, 0.2);
  --tux-colorLineSecondary2: rgba(255, 255, 255, 0);
  --tux-colorLineSecondary3: rgba(255, 255, 255, 0.5);
  --tux-colorLineDarker: rgba(0, 0, 0, 0.6);

  --tux-colorSDSecondary: rgba(0, 0, 0, 0.68);
  --tux-colorSDTertiary: rgba(0, 0, 0, 0.5);
}
