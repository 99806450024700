._TUXRadio-container {
  box-sizing: border-box;
  display: flex;
}

._TUXRadio-label {
  flex-grow: 1;
}

/* Use padding instead of margin so the gap between button and label remains clickable */
._TUXRadio-label--before {
  padding-inline-start: 0px;
  padding-inline-end: 12px;
}

._TUXRadio-label--after {
  padding-inline-start: 12px;
  padding-inline-end: 0px;
}
