@keyframes TUXSkeleton__background--light {
  0% {
    background-color: rgba(22, 24, 35, 0.1);
  }

  50.0% {
    background-color: var(--tux-colorBGView);
  }

  100.0% {
    background-color: rgba(22, 24, 35, 0.1);
  }
}

@keyframes TUXSkeleton__background--dark {
  0% {
    background-color: rgba(255, 255, 255, 0.1);
  }

  50.0% {
    background-color: var(--tux-colorBGView);
  }

  100.0% {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

._TUXSkeleton {
  background-color: var(--tux-colorBGView);
  animation-name: TUXSkeleton__background--light;
  animation-duration: 1600ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: running;
}

[data-theme='dark'] ._TUXSkeleton {
  animation-name: TUXSkeleton__background--dark;
}

._TUXSkeletonCircle {
  border-radius: 50%;
}

._TUXSkeletonRectangle {
  border-radius: 2px;
}

._TUXSkeletonCell {
  border-radius: 24px;
}
