._TUXModalHeader {
  box-sizing: border-box;

  min-height: 84px;
  overflow: hidden;

  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-self: space-between;

  padding: 24px;
  margin-top: 2px;
}

._TUXModalTitle {
  box-sizing: border-box;

  text-align: center;
  margin: 32px auto 8px;
  overflow: hidden;
}

._TUXModalBody {
  box-sizing: border-box;

  flex-grow: 1;
  flex-shrink: 1;

  overflow-y: auto;
  overflow-x: hidden;

  padding: 24px;
}

._TUXModalFooter {
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding: 24px;
  gap: 8px;
}

._TUXModalCloseButton {
  box-sizing: border-box;

  border: none;
  cursor: pointer;

  height: 32px;
  width: 32px;
  font-size: 32px;
  padding: 0;

  background: transparent;
  color: var(--tux-colorTextSecondary);
}
