/* Color */

.tux-color-TextPrimary {
  color: var(--tux-colorTextPrimary);
}

.tux-color-TextSecondary {
  color: var(--tux-colorTextSecondary);
}

.tux-color-TextTertiary {
  color: var(--tux-colorTextTertiary);
}

.tux-color-TextQuaternary {
  color: var(--tux-colorTextQuaternary);
}

.tux-color-Link {
  color: var(--tux-colorLink);
}

.tux-color-Primary {
  color: var(--tux-colorPrimary);
}

.tux-color-Primary2 {
  color: var(--tux-colorPrimary2);
}

.tux-color-Secondary {
  color: var(--tux-colorSecondary);
}

.tux-color-Secondary2 {
  color: var(--tux-colorSecondary2);
}

.tux-color-Positive {
  color: var(--tux-colorPositive);
}

.tux-color-Positive2 {
  color: var(--tux-colorPositive2);
}

.tux-colorNegative {
  color: var(--tux-colorNegative);
}

.tux-color-ConstTextPrimary {
  color: var(--tux-colorConstTextPrimary);
}

.tux-color-ConstTextInverse {
  color: var(--tux-colorConstTextInverse);
}

.tux-color-ConstTextInverse2 {
  color: var(--tux-colorConstTextInverse2);
}

.tux-color-ConstTextInverse3 {
  color: var(--tux-colorConstTextInverse3);
}

.tux-color-ConstTextInverse4 {
  color: var(--tux-colorConstTextInverse4);
}

.tux-color-ConstTextInverse5 {
  color: var(--tux-colorConstTextInverse5);
}

.tux-color-AssistColorYellow {
  color: var(--tux-colorAssistColorYellow);
}

/* Background color */

.tux-background-BGPrimary {
  background-color: var(--tux-colorBGPrimary);
}

.tux-background-BGSecondary {
  background-color: var(--tux-colorBGSecondary);
}

.tux-background-BGSecondary2 {
  background-color: var(--tux-colorBGSecondary2);
}

.tux-background-BGTertiary {
  background-color: var(--tux-colorBGTertiary);
}

.tux-background-BGTertiary2 {
  background-color: var(--tux-colorBGTertiary2);
}

.tux-background-BGQuaternary {
  background-color: var(--tux-colorBGQuaternary);
}

.tux-background-BGQuinary {
  background-color: var(--tux-colorBGQuinary);
}

.tux-background-BGInput {
  background-color: var(--tux-colorBGInput);
}

.tux-background-BGInput2 {
  background-color: var(--tux-colorBGInput2);
}

.tux-background-BGView {
  background-color: var(--tux-colorBGView);
}

.tux-background-BGHover {
  background-color: var(--tux-colorBGHover);
}

.tux-background-BGBrand {
  background-color: var(--tux-colorBGBrand);
}

.tux-background-BGPlaceholderDefault {
  background-color: var(--tux-colorBGPlaceholderDefault);
}

.tux-background-BGPlaceholderOpaque {
  background-color: var(--tux-colorBGPlaceholderOpaque);
}

.tux-background-ToastDefault {
  background-color: var(--tux-colorToastDefault);
}

.tux-background-ToastNegative {
  background-color: var(--tux-colorToastNegative);
}

.tux-background-ConstBGInverse {
  background-color: var(--tux-colorConstBGInverse);
}

.tux-background-ConstBGInverse1 {
  background-color: var(--tux-colorConstBGInverse1);
}

.tux-background-ConstBGInverse2 {
  background-color: var(--tux-colorBGConstBGInverse2);
}

.tux-background-ConstBGInverse3 {
  background-color: var(--tux-colorBGConstBGInverse3);
}

.tux-background-ConstBGInverse4 {
  background-color: var(--tux-colorBGConstBGInverse4);
}

.tux-background-BGCreation {
  background-color: var(--tux-colorBGCreation);
}

.tux-background-BGCreation2 {
  background-color: var(--tux-colorBGCreation2);
}

.tux-background-BGCreation3 {
  background-color: var(--tux-colorBGCreation3);
}

.tux-background-BGCreation4 {
  background-color: var(--tux-colorBGCreation4);
}

.tux-background-BGCreation5 {
  background-color: var(--tux-colorBGCreation5);
}

.tux-background-ConstBGMat {
  background-color: var(--tux-colorConstBGMat);
}

.tux-background-ConstBGContainer {
  background-color: var(--tux-colorConstBGContainer);
}

.tux-background-ConstBGContainer2 {
  background-color: var(--tux-colorConstBGContainer2);
}

.tux-background-ConstBGContainer3 {
  background-color: var(--tux-colorConstBGContainer3);
}

.tux-background-ConstBGPlaceholderOpaque {
  background-color: var(--tux-colorConstBGPlaceholderOpaque);
}
